<kendo-dialog title="{{TradLangue}}" *ngIf="openedReglage" (close)="actionReglage('annuler')" [minWidth]="250"
  [width]="450">
  <ng-container *ngFor="let lang of lstLangue">
    <ng-container>
      <div class="languerow" (click)="actionReglage('valider', lang.id)">
        <img src="{{url}}{{lang.code_langue}}.png" width="48px" height="48px">
        <label class="reglagelabel">&nbsp;&nbsp;&nbsp;&nbsp;
          {{lang.libelle}}
        </label>
      </div>
    </ng-container>
  </ng-container>
  <div class="text-right">
    <!-- <button kendoButton type="button" class="k-button btn btn-primary"
      (click)="actionReglage('valider')">Enregistrer</button> -->
    <button kendoButton type="button" class="k-button btn" (click)="actionReglage('annuler', 0)">{{TradAnnuler}}</button>
  </div>
</kendo-dialog>