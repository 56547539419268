<div class="login"></div>

<div class="container">
    <div class="row">

        <!-- login card-->
        <div class="offset-sm-3 offset-md-3 offset-lg-3 col-md-4 col-lg-4 col-sm-4 card card-block login-card">
            <div class="logo">
                <div class="logo-content" [inlineSVG]="'../../assets/images/hydrapool.svg'"></div>
            </div>
            <img [src]="languePicto" (click)="showReglageInDashBoard('langue')" width="48px" height="48px" style="float:right;margin-top:-65px;">
            <h2 class="form-label">{{(isModePro ? TradBienvenuePro : TradBienvenueUser)}}</h2>
            <span class="form-label">{{TradConnectExplain}}</span>
            <form class="k-form" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate class="k-form">
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    {{(isModePro ? TradAdresseEmail : TradNumSerie)}}
                    <kendo-textbox-container class="k-form-field">
                        <input kendoTextBox type="text" class="k-textbox" name="username" [(ngModel)]="model.username"
                            #username="ngModel" required title="{{TradUserExplain}}&#10;{{TradProExplain}}" autocomplete="username" />
                     </kendo-textbox-container>
                    <div *ngIf="f.submitted && !username.valid" class="help-block">{{TradNomOblig}}</div>
                </div>

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    {{TradMdp}}
                    <kendo-textbox-container class="k-form-field">
                        <input kendoTextBox type="password" class="k-textbox" name="password"
                            [(ngModel)]="model.password" #password="ngModel" required autocomplete="current-password" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !password.valid" class="help-block">{{TradMdpOblig}}</div>
                </div>

                <div class="form-group">
                    <button kendoButton [disabled]="loading" class="k-button btn btn-primary"
                        data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing Order">
                        <span class="seconnecter text-center">{{TradSeConnecter}}</span>
                        <!-- <img class="connect-content" src="../../assets/images/login2.svg"> -->
                    </button>
                    <a href="/motdepasse" class="btn btn-link">{{TradMdpOubli}}</a>
                    <a *ngIf="isModePro" href="/creationcompte" class="btn btn-link">{{TradCreerCompte}}</a>
                    <div class="text-center"><img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </div>

                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
            <div class="onglet-connexion-utilisateur" [ngClass]="{ 'onglet-connexion-inactif': isModePro }">
                <div class="onglet-txt" (click)="changeModeUtil()">
                    <div class="icon-pisciniste pull-left" [inlineSVG]="'../../assets/images/picto-user.svg'"></div>
                    <div class="pull-right">{{TradUtilisateur}}</div>
                </div>
            </div>
            <div class="onglet-connexion-pisciniste" [ngClass]="{ 'onglet-connexion-inactif': !isModePro }">
                <div class="onglet-txt" (click)="changeModePro()">
                    <div class="icon-pisciniste pull-left" [inlineSVG]="'../../assets/images/picto-professionnel.svg'">
                    </div>
                    <div class="pull-right">{{TradProfessionnel}}</div>
                </div>
            </div>
        </div>
        <!-- fin login card-->
    </div>
</div>
<reglagelangue parentName="login" (reglageClick)="showReglageInDashBoard($event)"></reglagelangue>