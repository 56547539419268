<div class="login"></div>
<div class="container">
    <div class="row">
        <div class="offset-sm-3 offset-md-3 offset-lg-3 col-md-4 col-lg-4 col-sm-4 card card-block login-card">
            <div class="logo">
                <div class="logo-content" [inlineSVG]="'../../assets/images/hydrapool.svg'"></div>
            </div>
            <h2 class="form-label">{{TradRecup}}</h2>
            <span class="form-label">{{TradSaisieEmail}}</span>
            <form class="k-form" name="form" (ngSubmit)="f.form.valid && checkemail()" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradEmail}}">
                        <input kendoTextBox type="text" class="form-control with-primary-addon" name="email"
                            [(ngModel)]="model.email" [email]="true" #email="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !email.valid " class="help-block">{{TradEmailOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !typecompte.valid }">
                    <label class="labelperso">{{TradTypeCompte}}</label>
                    <kendo-combobox [data]="listItems" name="typecompte" [textField]="'text'" [valueField]="'value'"
                        [allowCustom]="false" [(ngModel)]="model.typecompte" #typecompte="ngModel" required>
                    </kendo-combobox>
                    <div *ngIf="f.submitted && !typecompte.valid " class="help-block">{{TradTypeOblig}}</div>
                </div>
                <div *ngIf="nbUser>1" class="form-group"
                    [ngClass]="{ 'has-error': f.submitted && !identifiantcentrale.valid }">
                    <label>{{TradAssoMultiple}}</label>
                    <kendo-textbox-container class="k-form-field">
                        <input kendoTextBox type="text" placeholder="{{TradIdentifiant}}"
                            class="form-control with-primary-addon" name="identifiantcentrale"
                            [(ngModel)]="model.identifiantcentrale" #identifiantcentrale="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !identifiantcentrale.valid " class="help-block">{{TradIdentifiantOblig}}
                    </div>
                </div>
                <div class="form-group">
                    <button kendoButton [disabled]="loading" class="k-button btn btn-primary">{{TradDemander}}</button>
                    <a href="/connexion" class="btn btn-link">{{TradRetour}}</a>
                    <a href="/creation" class="btn btn-link">{{TradCreerCompte}}</a>
                    <div class="text-center"><img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </div>
    </div>
</div>