<div class="menu-logo">
  <div [inlineSVG]="'../../../../assets/images/hydrapool.svg'" [ngClass]="{'menu-hydrapool-show': sidebarToggle , 'menu-hydrapool-hide': !sidebarToggle}"></div>
  <div [inlineSVG]="'../../../../assets/images/logo-retract.svg'" [ngClass]="{'menu-retract-show': !sidebarToggle , 'menu-retract-hide': sidebarToggle}">
  </div>
</div>

<div id="onglet-collapse">
  <div class="collapse-menu" (click)="_sidebarToggle()">
    <i class="fa fa-angle-left" *ngIf="sidebarToggle"></i>
    <i class="fa fa-angle-right" *ngIf="!sidebarToggle"></i>
  </div>
</div>

<div class="menu-titre">{{TradMenu}}</div>
<ul class="menu-wrap animated fadeIn">
  <li *ngFor="let item of menuInfo;let i = index" class="menu-item" [ngClass]="{'item-toggle-on':item.toggle === 'on'}">
    <a [routerLink]="[item.routerLink.join('/')]" *ngIf="item.routerLink && item.niveaumax >= _globalService.myUser.droits && _globalService.myUser.droits >= item.niveaumin"
      routerLinkActive="active" class="menu-link" (click)="_selectItem(item)">
      <div class="placeholder">
        <div class="menu-icon" [inlineSVG]="item.picto"></div>
      </div>
      <span class="text">{{item.title}}</span>
    </a>
    <a class="menu-link" *ngIf="!item.routerLink && item.niveau >= _globalService.myUser.droits" (click)="isToggleOn(item)">
      <div class="placeholder">
        <i class="fa fa-{{item.icon}} fa-fw" *ngIf="item.icon"></i>
      </div>
      <span class="text">{{item.title}}</span>
      <span class="fa fa-angle-right fa-fw direction"></span>
    </a>
    <div class="item-children" [@collapse]="item.toggle" *ngIf="item.children">
      <du-menu [menuInfo]="item.children"></du-menu>
    </div>
  </li>
</ul>