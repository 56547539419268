<div class="content-wrap">
  <span>{{routeTitle}}</span>
  <i *ngIf="routeSubTitle != '' && routeSubTitle != null">/</i>
  <span>{{routeSubTitle}}</span>
  <div class="dashboard-toolbar">
    <span *ngIf="displayReleve===true">{{TradDernierReleve}} : {{dateReleve}}</span>
    <button kendoButton type="button" class="k-button btn-mini" (click)="onOpenReglage('tableaubord')"
      title="{{TradRglTdb}}" *ngIf="displayDashboard===true">
      <div class="icon-datatable-mini" [inlineSVG]="'../../../../assets/images/picto-tiles.svg'" *ngIf="displayDashboard===true"></div>
    </button>
  </div>
</div>
<reglagetableaubord></reglagetableaubord>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="ball-clip-rotate"></ngx-spinner>