<kendo-dialog title="{{TradRglAlertes}}" *ngIf="openedReglage" (close)="actionReglage('annuler')" [minWidth]="450"
  [width]="550" [(height)]="_globalService.popupBigMaxHeight">
  <div class="form-group row col-md-12">
    <kendo-combobox *ngIf="3 > _globalService.myUser.droits" [data]="modelPiscine" [textField]="'username'"
      [valueField]="'id'" [allowCustom]="false" (valueChange)="onPiscineChange($event)">
    </kendo-combobox>
    <img *ngIf="loadingNotif || loadingAlerte"
      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  </div>
  <form class="k-form" name="formSuspension" (ngSubmit)="f.formSuspension.valid" #f="ngForm" novalidate>
    <kendo-tabstrip>
      <kendo-tabstrip-tab [title]="TradNotifications" [selected]="true">
        <ng-template kendoTabContent>
          <div class="form-group">
            <ngx-accordion>
              <ngx-accordion-container *ngFor="let entry of modelNotification; let i = index;">
                <div class="ngx-accordion-header">{{entry.type_releve.libelle}}<span
                    class="float-sm-right float-md-right float-lg-right float-xl-right">
                    {{((entry.is_active) && TradActivee || TradDesactivee)}}</span>
                </div>
                <div class="ngx-accordion-body">
                  <div class="form-group row col-md-12">
                    <label class="col-md-7">{{TradActive}}</label>
                    <div class="col-md-5">
                      <kendo-switch [(ngModel)]="entry.is_active" [ngModelOptions]="{standalone: true}" [onLabel]="'I'"
                        [offLabel]="'O'" name="active{{i}}"></kendo-switch>
                    </div>
                  </div>
                  <div *ngIf="entry.is_active">
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSeuilMini}} : </label>
                      <div class="col-md-5">
                        <kendo-numerictextbox class="notif-numeric" [(ngModel)]="entry.seuil_mini"
                          name="seuil_mini{{i}}" #seuil_mini="ngModel" [min]="entry.type_releve.seuil_mini"
                          [max]="entry.type_releve.seuil_maxi" [step]="1" required>
                        </kendo-numerictextbox>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSeuilMaxi}} : </label>
                      <div class="col-md-5">
                        <kendo-numerictextbox class="notif-numeric" [value]="entry.seuil_maxi"
                          [(ngModel)]="entry.seuil_maxi" name="seuil_maxi{{i}}" #seuil_maxi="ngModel"
                          [min]="entry.type_releve.seuil_mini" [max]="entry.type_releve.seuil_maxi" [step]="1" required>
                        </kendo-numerictextbox>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradParMail}} : </label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_mail" name="is_mail" #is_mail="ngModel" [onLabel]="'I'"
                          [offLabel]="'O'" name="ismail{{i}}"></kendo-switch>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSurSmart}} : </label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_push" name="is_push" #is_push="ngModel" [onLabel]="'I'"
                          [offLabel]="'O'" name="ispush{{i}}"></kendo-switch>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSurWeb}} : </label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_web" name="is_web" #is_web="ngModel" [onLabel]="'I'"
                          [offLabel]="'O'" name="isweb{{i}}"></kendo-switch>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSurApp}} :</label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_mobile" name="is_mobile" #is_mobile="ngModel"
                          [onLabel]="'I'" [offLabel]="'O'" name="ismobile{{i}}"></kendo-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-accordion-container>
            </ngx-accordion>
          </div>
          <div class="text-right">
            <button kendoButton type="button" class="k-button btn btn-primary"
              (click)="actionReglage('valider')">{{TradEnregistrer}}</button>
            <button kendoButton type="button" class="k-button btn"
              (click)="actionReglage('annuler')">{{TradAnnuler}}</button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="TradAlertes">
        <ng-template kendoTabContent>
          <div class="form-group">
            <ngx-accordion>
              <ngx-accordion-container *ngFor="let entry of modelAlerte; let i = index;">
                <div class="ngx-accordion-header"> {{entry.type_alerte.libelle}}<span
                    class="float-sm-right float-md-right float-lg-right float-xl-right">
                    {{((entry.is_active) && TradActivee || TradDesactivee)}}</span>
                </div>
                <div class="ngx-accordion-body">
                  <div class="form-group row col-md-12">
                    <label class="col-md-7">{{TradActive}}</label>
                    <div class="col-md-5">
                      <kendo-switch [(ngModel)]="entry.is_active" [ngModelOptions]="{standalone: true}" [onLabel]="'I'"
                        [offLabel]="'O'" name="aisactive{{i}}"></kendo-switch>
                    </div>
                  </div>
                  <div *ngIf="entry.is_active">
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradParMail}} : </label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_mail" name="is_mail" #is_mail="ngModel" [onLabel]="'I'"
                          [offLabel]="'O'" name="aismail{{i}}"></kendo-switch>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSurSmart}} : </label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_push" name="is_push" #is_push="ngModel" [onLabel]="'I'"
                          [offLabel]="'O'" name="aispush{{i}}"></kendo-switch>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSurWeb}} : </label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_web" name="is_web" #is_web="ngModel" [onLabel]="'I'"
                          [offLabel]="'O'" name="aisweb{{i}}"></kendo-switch>
                      </div>
                    </div>
                    <div class="form-group row col-md-12">
                      <label class="col-md-7">{{TradSurApp}} :</label>
                      <div class="col-md-5">
                        <kendo-switch [(ngModel)]="entry.is_mobile" name="is_mobile" #is_mobile="ngModel"
                          [onLabel]="'I'" [offLabel]="'O'" name="aismobile{{i}}"></kendo-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-accordion-container>
            </ngx-accordion>
          </div>
          <div class="text-right">
            <button kendoButton type="button" class="k-button btn btn-primary"
              (click)="actionReglage('valider')">{{TradEnregistrer}}</button>
            <button kendoButton type="button" class="k-button btn" (click)="actionReglage('annuler')">{{TradAnnuler}}</button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </form>
  <div *ngIf="errorAssocie" class="alert alert-danger">{{errorAssocie}}</div>
</kendo-dialog>