<kendo-dialog title="{{TradRglTdb}}" *ngIf="openedReglage" (close)="actionReglage('annuler')" [minWidth]="250"
  [width]="450">
  <div class="text-right">
    <button kendoButton type="button" class="k-button btn-mini" (click)="actionReset()" title="{{TradInitTdb}}">
      <div class="icon-datatable-mini" [inlineSVG]="'../../../../assets/images/picto-reset.svg'"></div>
    </button>
  </div>
  <ng-container *ngFor="let param of LstParam">
    <ng-container *ngIf="param.isAddable">
      <div class="reglagerow">
        <label class="reglagelabel">
          {{param.label}}
        </label>
        <kendo-switch [(ngModel)]="param.isVisible" [onLabel]="'I'" [offLabel]="'O'"></kendo-switch>
      </div>
    </ng-container>
  </ng-container>
  <div class="text-right">
    <button kendoButton type="button" class="k-button btn btn-primary"
      (click)="actionReglage('valider')">{{TradEnregistrer}}</button>
    <button kendoButton type="button" class="k-button btn" (click)="actionReglage('annuler')">{{TradAnnuler}}</button>
  </div>
  <div *ngIf="errorAssocie" class="alert alert-danger">{{errorAssocie}}</div>
</kendo-dialog>