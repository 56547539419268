<div class="login"></div>
<div class="container">
    <div class="row">
        <div class="offset-sm-3 offset-md-3 offset-lg-3 col-md-4 col-lg-4 col-sm-4 card card-block login-card">
            <div class="logo">
                <div class="logo-content" [inlineSVG]="'../../assets/images/hydrapool.svg'"></div>
            </div>
            <h2 class="form-label">{{TradConfirmationCompte}}</h2>
            <div>
                <span *ngIf="IsUserValide" class="form-label">{{TradConfirmationMerci}}</span>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                <a *ngIf="IsUserValide" routerLink="/pages/index"><button
                        class="k-button btn btn-primary">{{TradAccesTdb}}</button></a>
                <a href="/connexion" class="k-button btn btn-link">{{TradRetourAccueil}}</a>
            </div>
        </div>
    </div>
</div>