<div class="login"></div>
<div class="container">
    <div class="row">
        <div class="offset-sm-3 offset-md-3 offset-lg-3 col-md-4 col-lg-4 col-sm-4 card card-block login-card">
            <div class="logo">
                <div class="logo-content" [inlineSVG]="'../../assets/images/hydrapool.svg'"></div>
            </div>
            <h2 class="form-label">{{TradCreationCompte}}</h2>
            <span class="form-label">{{TradCreationExplain}}</span>
            <form class="k-form" name="form" (ngSubmit)="f.form.valid && saveUserDetail()" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !societe.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradSociete}}">
                        <input type="text" kendoTextBox class="k-textbox" name="societe"
                            [(ngModel)]="model.coordonnees.societe" #societe="ngModel" required maxlength="50" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !societe.valid" class="help-block">{{TradSocieteOblig}}
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !siret.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradSiret}}">
                        <input type="text" kendoTextBox class="k-textbox" name="siret"
                            [(ngModel)]="model.coordonnees.siret" #siret="ngModel"
                            [required]="!model.coordonnees.tva_intra" maxlength="50" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !siret.valid" class="help-block">{{TradSiretOuTvaOblig}}
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !tva_intra.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradCodeTvaIntra}}">
                        <input type="text" kendoTextBox class="k-textbox" name="tva_intra"
                            [(ngModel)]="model.coordonnees.tva_intra" #tva_intra="ngModel"
                            [required]="!model.coordonnees.siret" maxlength="50" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !tva_intra.valid" class="help-block">{{TradSiretOuTvaOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !nom.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradNom}}">
                        <input type="text" kendoTextBox class="k-textbox" name="nom" [(ngModel)]="model.coordonnees.nom"
                            #nom="ngModel" required maxlength="50" /> </kendo-textbox-container>
                    <div *ngIf="f.submitted && !nom.valid" class="help-block">{{TradNomOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !prenom.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradPrenom}}">
                        <input type="text" kendoTextBox class="k-textbox" name="prenom"
                            [(ngModel)]="model.coordonnees.prenom" #prenom="ngModel" required maxlength="30" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !prenom.valid" class="help-block">{{TradPrenomOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !adresse.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradAdresse}}">
                        <input type="text" kendoTextBox class="k-textbox" name="adresse"
                            [(ngModel)]="model.coordonnees.adresse" #adresse="ngModel" required maxlength="38" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !adresse.valid" class="help-block">{{TradAdresseOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !code_postal.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradCodePostal}}">
                        <input type="text" kendoTextBox class="k-textbox" name="code_postal"
                            [(ngModel)]="model.coordonnees.code_postal" #code_postal="ngModel" required maxlength="9" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !code_postal.valid" class="help-block">{{TradCodePostalOblig}}
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !ville.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradVille}}">
                        <input type="text" kendoTextBox class="k-textbox" name="ville"
                            [(ngModel)]="model.coordonnees.ville" #ville="ngModel" required maxlength="35" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !ville.valid" class="help-block">{{TradVilleOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !pays.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradPays}}">
                        <input type="text" kendoTextBox class="k-textbox" name="pays"
                            [(ngModel)]="model.coordonnees.pays" #pays="ngModel" required maxlength="50" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !pays.valid" class="help-block">{{TradPaysOblig}}</div>
                </div>

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !mobile.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradMobile}}">
                        <input type="text" kendoTextBox class="k-textbox" name="mobile"
                            [(ngModel)]="model.coordonnees.mobile" #mobile="ngModel" required maxlength="25">
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !mobile.valid" class="help-block">{{TradMobileOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !site_internet.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradSiteInternet}}">
                        <input type="url" kendoTextBox class="k-textbox" name="site_internet"
                            [(ngModel)]="model.coordonnees.site_internet" #site_internet="ngModel" maxlength="75"
                            pattern="^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$"
                            title="Format http://xxx.xxx.xx" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !site_internet.valid" class="help-block">{{TradSiteInternetValide}}</div>
                </div>

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradEmail}}">
                        <input type="text" kendoTextBox class="k-textbox" name="email" [(ngModel)]="model.email"
                            [email]="true" #email="ngModel" required reverse="false" maxlength="180" autocomplete="username"/>
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !email.valid" class="help-block">{{TradEmailOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !plainemail.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradEmailConfirm}}">
                        <input type="text" minlength="9" kendoTextBox class="k-textbox" name="plainemail"
                            [(ngModel)]="model.plainemail" #plainemail="ngModel" required validateEqual="email"
                            appBlockCopyPaste />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !plainemail.valid " class="help-block">{{TradEmailConfirmOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradMdp}}">
                        <input type="password" minlength="9" kendoTextBox class="k-textbox" name="password"
                            [(ngModel)]="model.password" #password="ngModel" required reverse="false"
                            title="{{TradMdpOblig}}" autocomplete="new-password" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !password.valid " class="help-block">{{TradMdpOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !plainpassword.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradMdpConfirm}}">
                        <input type="password" minlength="9" kendoTextBox class="k-textbox" name="plainpassword"
                            [(ngModel)]="model.plainpassword" #plainpassword="ngModel" required validateEqual="password"
                            autocomplete="new-password" /></kendo-textbox-container>
                    <div *ngIf="f.submitted && !plainpassword.valid " class="help-block">{{TradMdpConfirmOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !is_rgpd.valid }">
                    <div class="k-form-field">
                        <input type="checkbox" id="ch1" value="1" class="k-checkbox" name="is_rgpd"
                            [(ngModel)]="model.coordonnees.is_rgpd" #is_rgpd="ngModel" required />
                        <label class="k-checkbox-label" for="ch1">{{TradCGU}}</label>
                        <i class="fa fa-question-circle fa-fw" *ngIf="!opened" (click)="open()"></i>
                        <div *ngIf="f.submitted && !is_rgpd.valid" class="help-block">{{TradCGUOblig}}</div>
                        <div>{{TradValidationAttente}}</div>
                    </div>
                </div>
                <kendo-dialog title="{{TradCGU}}" *ngIf="opened" (close)="close('cancel')" [minWidth]="250"
                    [width]="450" [(height)]="_globalService.popupBigMaxHeight">
                    <p style="margin: 30px; text-align: center;">{{TradCGUFull}}</p>
                    <kendo-dialog-actions>
                        <button kendoButton class="k-button btn btn-primary" (click)="close('yes')"
                            primary="true">{{TradFermer}}</button>
                    </kendo-dialog-actions>
                </kendo-dialog>
                <div class="form-group">
                    <button kendoButton [disabled]="loading" class="k-button btn btn-primary">{{TradValider}}</button>
                    <a href="/connexion" class="btn btn-link">{{TradAnnuler}}</a>
                    <div class="text-center">
                        <img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </div>
    </div>
</div>