<div class="login"></div>
<div class="container">
    <div class="row">
        <div class="offset-md-3 offset-lg-3 col-md-4 col-lg-4 card card-block login-card">
            <div class="logo">
                <div class="logo-content" [inlineSVG]="'../../assets/images/hydrapool.svg'"></div>
            </div>
            <h2 class="form-label">{{TradInitMdp}}</h2>
            <span *ngIf="IsUserValide" class="form-label">{{TradMdpNew}}</span>
            <form class="k-form" *ngIf="IsUserValide" name="form" (ngSubmit)="f.form.valid && changePassword()"
                #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradMdp}}">
                        <input type="password" minlength="9" maxlength="15" [pattern]="passPattern" kendoTextBox
                            class="k-textbox" name="password" [(ngModel)]="model.password" #password="ngModel" required
                            reverse="false" title="{{TradMdpExplain}}" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && password.errors?.pattern" class="help-block">{{TradMdpExplain}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !confirmPassword.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradMdpConfirm}}">
                        <input type="password" minlength="9" maxlength="15" [pattern]="passPattern" kendoTextBox
                            class="k-textbox" name="confirmPassword" [(ngModel)]="model.confirmPassword"
                            #confirmPassword="ngModel" required validateEqual="password" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !confirmPassword.valid " class="help-block">{{TradMdpNonIdentique}}
                    </div>
                </div>
                <div class="form-group">
                    <button kendoButton [disabled]="loading" class="k-button btn btn-primary">{{TradModifier}}</button>
                    <a href="/connexion" class="btn btn-link">{{TradRetour}}</a>
                    <a href="/creation" class="btn btn-link">{{TradCreerCompte}}</a>
                    <div class="text-center"><img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
            <form *ngIf="!IsUserValide">
                <span class="form-label">{{TradNonAutorise}}</span>
                <a href="/connexion" class="btn btn-link">{{TradRetour}}</a>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </div>
    </div>
</div>