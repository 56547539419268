<div class="login"></div>
<div class="container">
    <div class="row">
        <div class="offset-sm-3 offset-md-3 offset-lg-3 col-md-4 col-lg-4 col-sm-4 card card-block login-card">
            <div class="logo">
                <div class="logo-content" [inlineSVG]="'../../assets/images/hydrapool.svg'"></div>
            </div>
            <h2 class="form-label">{{TradPremConn}}</h2>
            <span class="form-label">{{TradRenseigne}}</span>
            <form class="k-form" name="form" (ngSubmit)="f.form.valid && saveUserDetail()" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !nom.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradNom}}">
                        <input type="text" kendoTextBox class="k-textbox" name="nom" [(ngModel)]="model.coordonnees.nom"
                            #nom="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !nom.valid" class="help-block">{{TradNomOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !prenom.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradPrenom}}">
                        <input type="text" kendoTextBox class="k-textbox" name="prenom"
                            [(ngModel)]="model.coordonnees.prenom" #prenom="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !prenom.valid" class="help-block">{{TradPrenomOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !adresse.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradAdresse}}">
                        <input type="text" kendoTextBox class="k-textbox" name="adresse"
                            [(ngModel)]="model.coordonnees.adresse" #adresse="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !adresse.valid" class="help-block">{{TradAdresseOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !code_postal.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradCodePostal}}">
                        <input type="text" kendoTextBox class="k-textbox" name="code_postal"
                            [(ngModel)]="model.coordonnees.code_postal" #code_postal="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !code_postal.valid" class="help-block">{{TradCodePostalOblig}}
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !ville.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradVille}}"> <input type="text"
                            kendoTextBox class="k-textbox" name="ville" [(ngModel)]="model.coordonnees.ville"
                            #ville="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !ville.valid" class="help-block">{{TradVilleOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !pays.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradPays}}">
                        <input type="text" kendoTextBox class="k-textbox" name="pays"
                            [(ngModel)]="model.coordonnees.pays" #pays="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !pays.valid" class="help-block">{{TradPaysOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradEmail}}">
                        <input type="text" kendoTextBox class="k-textbox" name="email" [(ngModel)]="model.email"
                            [email]="true" #email="ngModel" required reverse="false" maxlength="180" />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !email.valid" class="help-block">{{TradEmailOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !plainemail.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradEmailConfirm}}">
                        <input type="text" minlength="9" kendoTextBox class="k-textbox" name="plainemail"
                            [(ngModel)]="model.plainemail" #plainemail="ngModel" required validateEqual="email" appBlockCopyPaste />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !plainemail.valid " class="help-block">{{TradEmailConfirmOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !mobile.valid }">
                    <kendo-textbox-container class="k-form-field" floatingLabel="{{TradMobile}}">
                        <input type="text" kendoTextBox class="k-textbox" name="mobile"
                            [(ngModel)]="model.coordonnees.mobile" #mobile="ngModel" required />
                    </kendo-textbox-container>
                    <div *ngIf="f.submitted && !mobile.valid" class="help-block">{{TradMobileOblig}}</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !is_rgpd.valid }">
                    <div class="k-form-field">
                        <input type="checkbox" id="ch1" value="1" class="k-checkbox" name="is_rgpd"
                            [(ngModel)]="model.coordonnees.is_rgpd" #is_rgpd="ngModel" required />
                        <label class="k-checkbox-label" for="ch1">{{TradCGU}}</label>
                        <i class="fa fa-question-circle fa-fw" (click)="open()"></i>
                        <div *ngIf="f.submitted && !is_rgpd.valid" class="help-block">{{TradCGUOblig}}
                        </div>
                    </div>
                    <kendo-dialog title="{{TradCGU}}" *ngIf="opened" (close)="close('cancel')" [minWidth]="250"
                        [width]="450" [(height)]="_globalService.popupBigMaxHeight">
                        <p style="margin: 30px; text-align: center;">{{TradCGUFull}}
                        </p>
                        <kendo-dialog-actions>
                            <button class="k-button btn btn-primary" kendoButton (click)="close('yes')"
                                primary="true">{{TradFermer}}</button>
                        </kendo-dialog-actions>
                    </kendo-dialog>
                </div>
                <div class="form-group">
                    <button kendoButton [disabled]="loading" class="k-button btn btn-primary">{{TradValider}}</button>
                    <a href="/connexion" class="btn btn-link">{{TradAnnuler}}</a>
                    <div class="text-center"><img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </div>
    </div>
</div>