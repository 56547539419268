<div class="pages-top container-fluid">
    <div class="row">
        <!-- bloc search and user-->
        <div id="blocuser" class="col-sm-12 col-md-12 col-lg-12">
            <div class="container-fluid">
                <div class="row">
                    <!-- encart search-->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <a href="/assets/pdf/NT002-2019_HYDRAPOOL_UTILI.pdf" target="_blank">
                            <div class="icon-contenttop"
                                [inlineSVG]="'../../../../assets/images/notice-utilisateur.svg'"
                                title="{{TradNoticeUtilisateur}}"></div>
                        </a>
                        <a *ngIf="3>_globalService.myUser.droits" href="/assets/pdf/ NT001-2019_HYDRAPOOL-PRO.pdf"
                            target="_blank">
                            <div class="icon-contenttop" [inlineSVG]="'../../../../assets/images/notice-pro.svg'"
                                title="{{TradNoticePro}}"></div>
                        </a>
                        <a href="mailto:contact@hydrapool.fr">
                            <div class="icon-contenttop" [inlineSVG]="'../../../../assets/images/picto-contact.svg'"
                                title="{{TradContact}}"></div>
                        </a>
                    </div>

                    <!-- encart user-->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="avatar-wrap">
                            <div class="msg-tips-wrap">
                                <button #notif (click)="onToggleNotif()" class="k-button notifpop">
                                    <div class="msg-entrance ring"
                                        [inlineSVG]="'../../../../assets/images/picto-alertes.svg'">
                                    </div>
                                    <span class="badge" *ngIf="nbAlerte!==0">{{nbAlerte}}</span>
                                </button>
                            </div>
                            <div id="notifmenu">
                                <kendo-popup [popupClass]="''" [anchor]="notif" [anchorAlign]="anchorAlign"
                                    [popupAlign]="popupAlign" (anchorViewportLeave)="showNotif = false"
                                    *ngIf="showNotif">
                                    <div class="usermenu-link" (click)="goMesAlertes()">{{TradMesAlertes}}</div>
                                    <div class="usermenu-link" (click)="goReglageAlertes()">{{TradRglAlertes}}</div>
                                </kendo-popup>
                            </div>
                            <div>
                                <div class="avatar">
                                    <div [inlineSVG]="'../../../../assets/images/user-2.svg'"></div>
                                </div>
                            </div>
                            <div>
                                <div class="user-info-wrap">
                                    <span class="user-post">{{TradBienvenue}}</span>
                                    <span class="user-name"
                                        title="{{_globalService.myUser.coordonnees.prenom}}">{{_globalService.myUser.coordonnees.prenom}}</span>
                                </div>
                            </div>

                            <div class="usermenu-header"> 
                                <button #anchor (click)="onToggle()" class="k-button userpop"><span
                                        class="fa fa-angle-down"></span></button>
                            </div>
                            <div id="connectmenu">
                                <kendo-popup [popupClass]="''" [anchor]="anchor" [anchorAlign]="anchorAlign"
                                    [popupAlign]="popupAlign" (anchorViewportLeave)="show = false" *ngIf="show">
                                    <div *ngIf="_globalService.myUser.droits>1" class="usermenu-link"
                                        (click)="goMonCompte()">{{TradMonCompte}}</div>
                                    <div class="usermenu-link" (click)="goPreferences()">{{TradMesPreferences}}</div>
                                    <div class="usermenu-link" (click)="logout()">{{TradDeconnexion}}</div>
                                </kendo-popup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<reglagenotification></reglagenotification>